<template>
  <BCard>
    <div class="text-black text-[20px] mb-2 font-[600]">
      Document Request Collaborator
    </div>
    <div class="d-flex flex-column flex-lg-row justify-content-end gap-5">
      <date-range-picker
        ref="picker"
        v-model="dateRange"
        :locale-data="locale"
        :ranges="ranges"
        :opens="'left'"
        control-container-class="border rounded"
      >
        <template v-slot:input="picker">
          <div
            class="d-flex btn justify-content-between align-items-center gap-5"
            style="height: 38px;"
          >
            <span class="d-flex justify-content-start">Date Range</span>
            <img
              src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/arrow-down-1.svg"
              alt="arrow-down"
              width="15px"
              class="d-flex justify-content-end"
            >
          </div>
          <span class="d-none">{{ picker }}</span>
        </template>
      </date-range-picker>
      <VSelect
        v-model="statusRequest"
        :reduce="e => e.value"
        :options="optionsStatus"
        label="text"
        :clearable="false"
        class="font-semibold !text-[#333] col-sm-6 col-lg-2 col-md-4"
        @input="getListDoc()"
      />
      <b-form-group class="mb-0 text-end d-flex flex-row align-items-center">
        <div class="d-flex gap-5">
          <b-input-group
            class="input-group-merge"
            size="md"
          >
            <b-input-group-prepend is-text>
              <feather-icon icon="SearchIcon" />
            </b-input-group-prepend>
            <b-form-input
              id="filterInput"
              v-model="search"
              :maxlength="32"
              :minlength="2"
              type="search"
              placeholder="Search Collaborator"
              debounce="500"
              @input="onChangeSearch"
            />
          </b-input-group>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-primary"
            size="md"
            @click="resetFilter()"
          >
            Reset
          </b-button>
        </div>
      </b-form-group>
    </div>
    <BOverlay
      :show="loading"
      spinner-variant="primary"
      variant="light"
      blur="0"
      opacity=".5"
      rounded="sm"
    >
      <BTable
        id="scroll"
        ref="table-scroll"
        :items="listCollaborator"
        :fields="fieldsRequestDoc"
        show-empty
        empty-text="Tidak ada data yang ditampilkan."
        responsive
        sticky-header="500px"
        class="mt-1"
        @scroll.native="handleScroll"
      >
        <template #cell(date)="data">
          <div
            style="min-width: 20px !important"
            class="d-flex flex-column"
          >
            {{ formatDate(data.item.date) }}
            <small class="text-secondary">{{ formatHours(data.item.date) }}</small>
          </div>
        </template>
        <template #cell(name)="data">
          <div
            class="d-flex flex-column"
          >
            {{ data.item.name }}
            <small class="text-secondary">{{ data.item.email }}</small>
          </div>
        </template>
        <template #cell(contact)="data">
          <div class="d-flex align-items-center gap-5">
            {{ data.item.contact }}
            <BButton
              class="p-0 justify-between items-center"
              type="button"
              aria-expanded="false"
              variant="light"
              style="width: 20px !important;"
              @click="checkWA(data.item.contact)"
            >
              <img
                v-if="data.item.contact"
                src="https://storage.googleapis.com/komerce//whatsapp-ori.svg"
                alt="package"
              >
            </BButton>
          </div>
        </template>
        <template #cell(sla)="data">
          <div class="d-flex align-items-center gap-5">
            <img
              :src="statusIcon(data.item.document_sla_status)"
              alt="status"
            >
          </div>
        </template>
        <template #cell(ca)="data">
          <div class="d-flex align-items-center gap-5">
            <img
              :src="statusIcon(data.item.document_ca_status)"
              alt="status"
            >
          </div>
        </template>
        <template #cell(uat)="data">
          <div class="d-flex align-items-center gap-5">
            <img
              :src="statusIcon(data.item.uat_status)"
              alt="status"
            >
          </div>
        </template>
        <template #cell(detail)="data">
          <div class="d-flex align-items-center gap-5 detail">
            <span
              class="cursor-pointer text-primary text-bold"
              @click="navigateToDetail(data.item.user_id)"
            >Detail</span>
          </div>
        </template>
      </BTable>
    </BOverlay>
  </BCard>
</template>
<script>
import Ripple from 'vue-ripple-directive'
import { openApiAxiosIns, komshipAxiosIns } from '@/libs/axios'
import {
  LABELDAY, LABELMONTH, YEAR_MONTH_DAY,
} from '@/libs/filterDate'
import moment from 'moment'
import {
  today,
  last7,
  last2,
  last30,
  last3,
  firstDateOfMonth,
  lastDateOfMonth,
  lastMonth,
  lastDateOfLastMonth,
} from '@/store/helpers'
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import VSelect from 'vue-select'
import '@core/scss/vue/libs/vue-select.scss'
import { fieldsRequestDoc } from '../config'

export default {
  directives: {
    Ripple,
  },
  components: {
    DateRangePicker,
    VSelect,
  },
  data() {
    return {
      loading: false,
      search: '',
      fieldsRequestDoc,
      YEAR_MONTH_DAY,
      picker: {
        startDate: firstDateOfMonth,
        endDate: lastDateOfMonth,
      },
      locale: {
        format: 'dd/mm/yyyy',
        daysOfWeek: LABELDAY,
        monthNames: LABELMONTH,
      },
      ranges: {
        'Last 3 Days': [last3, today],
        'Last Week': [last7, today],
        'This Month': [firstDateOfMonth, today],
        'Last Month': [lastMonth(1), lastDateOfLastMonth],
        'Custom Range': [today, today],
      },
      today,
      last7,
      last2,
      last30,
      last3,
      firstDateOfMonth,
      lastDateOfMonth,
      dateRange: {
        startDate: today,
        endDate: today,
      },
      statusRequest: 'all',
      optionsStatus: [
        {
          text: 'All',
          value: 'all',
        },
        {
          text: 'Request',
          value: 'request',
        },
        {
          text: 'Rejected',
          value: 'rejected',
        },
        {
          text: 'Accepted',
          value: 'approved',
        },
      ],
      listCollaborator: [],
      limit: 20,
      offset: 0,
      lastData: false,
      isWAValid: true,
    }
  },
  watch: {
    dateRange: {
      handler() {
        this.offset = 0
        this.limit = 20
        this.getListDoc()
      },
    },
  },
  mounted() {
    this.getListDoc()
  },
  methods: {
    formatDate(d) {
      return `${moment(d)
        .format('MMM DD, YYYY')}`
    },
    formatHours(d) {
      return `${moment(d)
        .format('HH:mm:ss')} WIB`
    },
    navigateToDetail(v) {
      this.$router.push({
        path: `/openapi/document-request/detail/${v}`,
      })
    },
    onChangeSearch: _.debounce(function search() {
      this.offset = 0
      this.limit = 20
      const trimmedSearch = this.search.trim()
      this.search = trimmedSearch

      if (trimmedSearch.length > 2) {
        this.getListDoc()
      } else if (trimmedSearch === '') {
        this.getListDoc()
      }
    }, 1000),
    formatDateParams(d) {
      return moment(d)
        .format('YYYY-MM-DD')
    },
    handleScroll(event) {
      const { scrollTop, scrollHeight, clientHeight } = event.target
      if (scrollTop + clientHeight >= scrollHeight - 1 && !this.loading
      && !this.lastData) {
        this.offset += 20
        this.getListDocNext()
      }
    },
    async getListDoc() {
      const params = {
        limit: this.limit,
        offset: this.offset,
        search: this.search,
        start_date: this.formatDateParams(this.dateRange.startDate),
        end_date: this.formatDateParams(this.dateRange.endDate),
        status: this.statusRequest,
      }
      this.loading = true
      const scrollContainer = this.$refs['table-scroll']?.$el

      const url = '/user/api/v1/admin/document-request'
      try {
        const res = await openApiAxiosIns.get(url, { params })
        const { list, count } = res.data.data

        this.listCollaborator = list
        this.loading = false

        if (scrollContainer) {
          scrollContainer.scrollTop = 0
        }

        this.lastData = count < this.limit
      } catch (error) {
        this.loading = false
        this.$toast_error({ message: 'Failed to retrieve data' })
      }
    },
    async getListDocNext() {
      if (!this.lastData) {
        const params = {
          limit: this.limit,
          offset: this.offset,
          search: this.search,
          start_date: this.formatDateParams(this.dateRange.startDate),
          end_date: this.formatDateParams(this.dateRange.endDate),
          status: this.statusRequest,
        }
        this.loading = true

        const url = '/user/api/v1/admin/document-request'
        try {
          const res = await openApiAxiosIns.get(url, { params })
          const { list, count } = res.data.data

          this.listCollaborator = [...this.listCollaborator, ...list]
          this.loading = false

          this.lastData = count < this.limit
        } catch (error) {
          this.loading = false
          this.$toast_error({ message: 'Failed to retrieve data' })
        }
      }
    },
    checkWA(number) {
      if (number.startsWith('8')) {
        // eslint-disable-next-line no-param-reassign
        number = `62${number}`
      } else if (number.startsWith('0')) {
        // eslint-disable-next-line no-param-reassign
        number = number.replace(/^0/, '62')
      }
      const url = '/v1/check-wa'
      komshipAxiosIns
        .post(url, { phone_no: number })
        .then(res => {
          const { data } = res.data
          if (data === 'valid') {
            this.isWAValid = true
            this.$toast_success({ message: 'Phone Number Connected to Whatsapp' })
            window.open(`https://wa.me/${number}`, '_blank')
          } else {
            this.isWAValid = false
            this.$toast_error({ message: 'Phone Number not Connected to Whatsapp' })
          }
        })
        .catch(err => {
          this.isWAValid = false
          // eslint-disable-next-line no-console
          console.log(err)
          this.$toast_error({ message: 'Phone Number not Connected to Whatsapp' })
        })
    },
    resetFilter() {
      // eslint-disable-next-line no-unused-expressions, no-sequences
      this.search = '',
      this.statusRequest = 'all',
      this.dateRange.startDate = today,
      this.dateRange.endDate = today,
      this.limit = 20,
      this.offset = 0,
      this.getListDoc()
    },
    statusIcon(v) {
      if (v <= 4) return 'https://storage.googleapis.com/komerce/icon/circle-minus-gray.svg'
      if (v === 5) return 'https://storage.googleapis.com/komerce/assets/icons/Checklist-fill.svg'
      if (v === 6) return 'https://storage.googleapis.com/komerce/assets/LP-Komads/Symbol%20close.svg'
      return ''
    },
  },
}
</script>
